import { EventKey, Event, ReclaimEventType } from "../reclaim-api/Events";
import { Recurrence } from "../reclaim-api/OneOnOnes";
import { AssignmentOrEventType } from "../types";

export function parseEventKey(eventKey: EventKey): { eventId: string; calendarId: number } {
  const [, calendarIdStr, eventId] = eventKey.match(/^(\d+)\/(.+)$/) || [];
  const calendarId = parseInt(calendarIdStr);
  if (!calendarIdStr || isNaN(calendarId) || !eventId) throw new Error(`Invalid eventKey format: ${eventKey}`);
  return { calendarId, eventId };
}

export function getEffectiveRecurrence(recurrence?: Recurrence | null, timesPerPeriod?: number): Recurrence {
  if (!recurrence) {
    if (timesPerPeriod === 7) return Recurrence.Daily;
    else return Recurrence.Weekly;
  }

  return recurrence;
}

export const getAssignmentIdAndType = (event: Event): { assignmentId: number | string; type: AssignmentOrEventType } => {
  if (!!event.assist?.dailyHabitId) {
    if (event?.reclaimEventType === ReclaimEventType.OneOnOneAssignment) {
      return { assignmentId: event.assist?.dailyHabitId, type: "one-on-one" };
    } else {
      return { assignmentId: event.assist.dailyHabitId, type: "habit" };
    }
  } else if (!!event?.assist?.taskId) {
    return { assignmentId: event.assist.taskId, type: "task" };
  } else {
    return { assignmentId: event.key, type: "event" };
  }
};

