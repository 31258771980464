export interface ReturnFormatArray<OKType, ErrorType> extends Array<OKType | ErrorType> {
  [0]: OKType;
  [1]: ErrorType;
}

export const isReturnFormatArray = (arr: unknown): arr is ReturnFormatArray<unknown, unknown> => {
  if (
    Array.isArray(arr) &&
    arr.length === 2 &&
    !(arr[0] instanceof Error) &&
    (arr[1] instanceof Error || arr[1] === null)
  )
    return true;

  return false;
};

export const createReturnFormatArray = <OKType, ErrorType>(
  okData: OKType,
  errorData: ErrorType
): ReturnFormatArray<OKType, ErrorType> => {
  const returned: ReturnFormatArray<OKType, ErrorType> = [okData, errorData];
  return returned as ReturnFormatArray<OKType, ErrorType>;
};
